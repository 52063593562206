import React from "react";
import {
  Bold,
  Italic,
  Link,
  Image,
  ListOrdered,
  List,
  AlignLeft,
  AlignCenter,
  AlignRight,
  Type,
  Heading1,
  Heading2,
} from "lucide-react";
import "../styles/htmlEditorToolbarStyle.css";

const HtmlEditorToolbar = ({ onFormat }) => {
  const handleFormat = (tag, attributes = {}) => {
    const selection = window.getSelection();
    if (!selection.toString()) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();

    let formattedText = "";
    switch (tag) {
      case "b":
        formattedText = `<strong>${selectedText}</strong>`;
        break;
      case "i":
        formattedText = `<em>${selectedText}</em>`;
        break;
      case "a":
        const url = prompt("Enter URL:");
        if (url) {
          formattedText = `<a href="${url}" target="_blank">${selectedText}</a>`;
        }
        break;
      case "ul":
        formattedText = `<ul>\n  <li>${selectedText}</li>\n</ul>`;
        break;
      case "ol":
        formattedText = `<ol>\n  <li>${selectedText}</li>\n</ol>`;
        break;
      case "h1":
        formattedText = `<h1>${selectedText}</h1>`;
        break;
      case "h2":
        formattedText = `<h2>${selectedText}</h2>`;
        break;
      case "align":
        formattedText = `<div style="text-align: ${attributes.align}">${selectedText}</div>`;
        break;
      default:
        formattedText = selectedText;
    }

    onFormat(formattedText);
  };

  const insertTemplate = (type) => {
    let template = "";
    switch (type) {
      case "button":
        template = `<div class="cta-button"><a href="#" style="display: inline-block; background-color: #3498db; color: white; padding: 12px 30px; text-decoration: none; border-radius: 25px; font-weight: bold;">Click Here</a></div>`;
        break;
      case "twoColumn":
        template = `<table width="100%"><tr><td width="50%" style="padding: 10px;">Column 1 content</td><td width="50%" style="padding: 10px;">Column 2 content</td></tr></table>`;
        break;
      case "spacer":
        template = `<div style="height: 20px;"></div>`;
        break;
    }
    onFormat(template);
  };

  return (
    <div className="html-editor-toolbar">
      <div className="toolbar-group">
        <button
          className="toolbar-button"
          onClick={() => handleFormat("b")}
          title="Bold"
        >
          <Bold size={16} />
        </button>
        <button
          className="toolbar-button"
          onClick={() => handleFormat("i")}
          title="Italic"
        >
          <Italic size={16} />
        </button>
      </div>

      <div className="toolbar-group">
        <button
          className="toolbar-button"
          onClick={() => handleFormat("h1")}
          title="Heading 1"
        >
          <Heading1 size={16} />
        </button>
        <button
          className="toolbar-button"
          onClick={() => handleFormat("h2")}
          title="Heading 2"
        >
          <Heading2 size={16} />
        </button>
      </div>

      <div className="toolbar-group">
        <button
          className="toolbar-button"
          onClick={() => handleFormat("ul")}
          title="Bullet List"
        >
          <List size={16} />
        </button>
        <button
          className="toolbar-button"
          onClick={() => handleFormat("ol")}
          title="Numbered List"
        >
          <ListOrdered size={16} />
        </button>
      </div>

      <div className="toolbar-group">
        <button
          className="toolbar-button"
          onClick={() => handleFormat("align", { align: "left" })}
          title="Align Left"
        >
          <AlignLeft size={16} />
        </button>
        <button
          className="toolbar-button"
          onClick={() => handleFormat("align", { align: "center" })}
          title="Align Center"
        >
          <AlignCenter size={16} />
        </button>
        <button
          className="toolbar-button"
          onClick={() => handleFormat("align", { align: "right" })}
          title="Align Right"
        >
          <AlignRight size={16} />
        </button>
      </div>

      <div className="toolbar-group">
        <button
          className="toolbar-button"
          onClick={() => handleFormat("a")}
          title="Insert Link"
        >
          <Link size={16} />
        </button>
      </div>

      <div className="toolbar-separator" />

      <div className="toolbar-group">
        <select
          className="template-select"
          onChange={(e) => insertTemplate(e.target.value)}
          value=""
        >
          <option value="">Insert Template...</option>
          <option value="button">CTA Button</option>
          <option value="twoColumn">Two Columns</option>
          <option value="spacer">Spacer</option>
        </select>
      </div>
    </div>
  );
};

export default HtmlEditorToolbar;
