import React, { useState, useEffect } from "react";
import { Plus, Save, Eye, Variable } from "lucide-react";
import "../styles/emailTemplateEdit.css";
import { db } from "../config/firebaseSetup";
import {
  collection,
  getDocs,
  doc,
  addDoc,
  updateDoc,
  query,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore";
import HtmlEditorToolbar from "./HTMLEditorToolBar";
import prettier from "prettier/standalone";
import parserHtml from "prettier/parser-html";

const TEMPLATES_PER_PAGE = 10;

const EmailTemplateEditor = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [currentLanguage, setCurrentLanguage] = useState("english");
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [showVariables, setShowVariables] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [formData, setFormData] = useState({
    label: "",
    subjectEnglish: "",
    subjectItalian: "",
    emailEnglish: "",
    emailItalian: "",
  });

  const availableVariables = [
    "recipientName",
    "senderName",
    "senderPosition",
    "companyName",
    "auctionImage",
    "link",
  ];

  useEffect(() => {
    loadInitialTemplates();
  }, []);

  useEffect(() => {
    const options = {
      root: document.querySelector(".template-list"),
      rootMargin: "20px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting && hasMore && !isLoadingMore) {
        loadMoreTemplates();
      }
    }, options);

    const sentinel = document.querySelector("#scroll-sentinel");
    if (sentinel) {
      observer.observe(sentinel);
    }

    return () => observer.disconnect();
  }, [hasMore, isLoadingMore, lastVisible]);

  useEffect(() => {
    const mockTemplates = [
      {
        id: "bid-template",
        label: "For User Bid",
        subjectEnglish: "Congratulations on Placing the Highest Bid!",
        subjectItalian:
          "Congratulazioni per aver effettuato l'offerta più alta!",
        emailEnglish:
          "<!DOCTYPE html><html><body><h1>Congratulations!</h1></body></html>",
        emailItalian:
          '<!DOCTYPE html><html lang="it"><body><h1>Congratulazioni!</h1></body></html>',
      },
      {
        id: "welcome",
        label: "Welcome Email",
        subjectEnglish: "Welcome to Our Platform",
        subjectItalian: "Benvenuto sulla nostra piattaforma",
        emailEnglish:
          "<!DOCTYPE html><html><body><h1>Welcome!</h1></body></html>",
        emailItalian:
          '<!DOCTYPE html><html lang="it"><body><h1>Benvenuto!</h1></body></html>',
      },
    ];
    setTemplates(mockTemplates);
  }, []);

  const loadMoreTemplates = async () => {
    if (!hasMore || isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      const result = await emailTemplateService.fetchTemplates(lastVisible);
      setTemplates((prev) => [...prev, ...result.templates]);
      setLastVisible(result.lastVisible);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error("Error loading more templates:", error);
    } finally {
      setIsLoadingMore(false);
    }
  };

  const loadTemplates = async (isLoadMore = false) => {
    try {
      setLoading(true);
      const result = await emailTemplateService.fetchTemplates(
        isLoadMore ? lastVisible : null
      );

      setTemplates((prev) =>
        isLoadMore ? [...prev, ...result.templates] : result.templates
      );
      setLastVisible(result.lastVisible);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error("Error loading templates:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateNew = () => {
    setSelectedTemplate(null);
    setFormData({
      label: "",
      subjectEnglish: "",
      subjectItalian: "",
      emailEnglish: "",
      emailItalian: "",
    });
    setEditMode(true);
    setPreviewMode(false);
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      if (selectedTemplate) {
        await emailTemplateService.updateTemplate(
          selectedTemplate.id,
          formData
        );
      } else {
        await emailTemplateService.createTemplate(formData);
      }
      await loadTemplates();
      setEditMode(false);
    } catch (error) {
      console.error("Error saving template:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleVariableInsert = (variable) => {
    const textArea = document.querySelector("textarea");
    if (textArea) {
      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const currentContent =
        currentLanguage === "english"
          ? formData.emailEnglish
          : formData.emailItalian;
      const newContent =
        currentContent.substring(0, start) +
        `{{${variable}}}` +
        currentContent.substring(end);

      setFormData({
        ...formData,
        [currentLanguage === "english" ? "emailEnglish" : "emailItalian"]:
          newContent,
      });
    }
    setShowVariables(false);
  };

  const loadInitialTemplates = async () => {
    try {
      setIsInitialLoading(true);
      const result = await emailTemplateService.fetchTemplates(null);
      setTemplates(result.templates);
      setLastVisible(result.lastVisible);
      setHasMore(result.hasMore);
    } catch (error) {
      console.error("Error loading templates:", error);
    } finally {
      setIsInitialLoading(false);
    }
  };

  const emailTemplateService = {
    async fetchTemplates(lastDoc = null) {
      try {
        const emailsRef = collection(db, "emailNotifications");
        let q = lastDoc
          ? query(
              emailsRef,
              orderBy("created", "desc"),
              startAfter(lastDoc),
              limit(TEMPLATES_PER_PAGE)
            )
          : query(
              emailsRef,
              orderBy("created", "desc"),
              limit(TEMPLATES_PER_PAGE)
            );

        const snapshot = await getDocs(q);
        const templates = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const lastVisible = snapshot.docs[snapshot.docs.length - 1];

        return {
          templates,
          lastVisible,
          hasMore: snapshot.docs.length === TEMPLATES_PER_PAGE,
        };
      } catch (error) {
        console.error("Error fetching templates:", error);
        throw error;
      }
    },

    async createTemplate(templateData) {
      try {
        const emailsRef = collection(db, "emailNotifications");
        const newTemplate = {
          ...templateData,
          created: new Date().toISOString(),
        };
        const docRef = await addDoc(emailsRef, newTemplate);
        return { id: docRef.id, ...newTemplate };
      } catch (error) {
        console.error("Error creating template:", error);
        throw error;
      }
    },

    async updateTemplate(templateId, templateData) {
      try {
        const templateRef = doc(db, "emailNotifications", templateId);
        await updateDoc(templateRef, {
          ...templateData,
          updated: new Date().toISOString(),
        });
        return { id: templateId, ...templateData };
      } catch (error) {
        console.error("Error updating template:", error);
        throw error;
      }
    },
  };

  const formatHtmlContent = () => {
    try {
      const formattedHtmlEnglish = prettier.format(formData.emailEnglish, {
        parser: "html",
        plugins: [parserHtml],
        printWidth: 80,
        tabWidth: 2,
        useTabs: false,
        htmlWhitespaceSensitivity: "ignore",
      });

      const formattedHtmlItalian = prettier.format(formData.emailItalian, {
        parser: "html",
        plugins: [parserHtml],
        printWidth: 80,
        tabWidth: 2,
        useTabs: false,
        htmlWhitespaceSensitivity: "ignore",
      });

      setFormData({
        ...formData,
        emailEnglish: formattedHtmlEnglish,
        emailItalian: formattedHtmlItalian,
      });
    } catch (error) {
      console.error("Error formatting HTML:", error);
      alert("Error formatting HTML. Please check if the HTML is valid.");
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
    setFormData(template);
    setEditMode(false);
    setPreviewMode(false);
  };

  return (
    <div className="app-container">
      <div className="sidebar">
        <div className="sidebar-header">
          <h2>Email Templates</h2>
          <button
            className="button-primary"
            onClick={handleCreateNew}
            disabled={isInitialLoading}
          >
            <Plus className="icon" /> New Template
          </button>
        </div>

        <div className="template-list">
          {isInitialLoading ? (
            <>
              <div className="template-skeleton" />
              <div className="template-skeleton" />
              <div className="template-skeleton" />
            </>
          ) : (
            <>
              {templates.map((template) => (
                <button
                  key={template.id}
                  className={`template-item ${
                    selectedTemplate?.id === template.id ? "active" : ""
                  }`}
                  onClick={() => handleTemplateSelect(template)}
                >
                  {template.label}
                </button>
              ))}
              <div id="scroll-sentinel" style={{ height: "1px" }} />
              {isLoadingMore && (
                <div className="loading-indicator">
                  Loading more templates...
                </div>
              )}
              {!hasMore && templates.length > 0 && (
                <div className="loading-indicator">No more templates</div>
              )}
              {!isInitialLoading && templates.length === 0 && (
                <div className="loading-indicator">
                  No templates found. Create your first template!
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="content-area">
        <div className="content-header">
          <div className="header-left">
            <h1 className="content-title">
              {selectedTemplate ? "Edit Template" : "Create New Template"}
            </h1>
            <div className="view-toggle">
              <button
                className={`button-outline ${!previewMode ? "active" : ""}`}
                onClick={() => setPreviewMode(false)}
              >
                Edit
              </button>
              <button
                className={`button-outline ${previewMode ? "active" : ""}`}
                onClick={() => setPreviewMode(true)}
              >
                <Eye className="icon" /> Preview
              </button>
            </div>
          </div>
          <div className="header-actions">
            <div className="variables-wrapper">
              <button
                className="button-outline"
                onClick={() => setShowVariables(!showVariables)}
              >
                <Variable className="icon" /> Variables
              </button>
              {showVariables && (
                <div className="variables-menu">
                  {availableVariables.map((variable) => (
                    <button
                      key={variable}
                      className="variable-item"
                      onClick={() => handleVariableInsert(variable)}
                    >
                      {`{{${variable}}}`}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="editor-content">
          {previewMode ? (
            <div className="preview-mode">
              <div className="preview-container">
                <div className="preview-header">
                  <select
                    value={currentLanguage}
                    onChange={(e) => setCurrentLanguage(e.target.value)}
                    className="language-selector"
                  >
                    <option value="english">English</option>
                    <option value="italian">Italian</option>
                  </select>
                </div>
                <div className="preview-email">
                  <div className="preview-subject">
                    {currentLanguage === "english"
                      ? formData.subjectEnglish
                      : formData.subjectItalian}
                  </div>
                  <div
                    className="preview-body"
                    dangerouslySetInnerHTML={{
                      __html:
                        currentLanguage === "english"
                          ? formData.emailEnglish
                          : formData.emailItalian,
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="edit-mode">
              <div className="language-tabs">
                <button
                  className={`tab ${
                    currentLanguage === "english" ? "active" : ""
                  }`}
                  onClick={() => setCurrentLanguage("english")}
                >
                  English
                </button>
                <button
                  className={`tab ${
                    currentLanguage === "italian" ? "active" : ""
                  }`}
                  onClick={() => setCurrentLanguage("italian")}
                >
                  Italian
                </button>
              </div>

              <div className="form-group">
                <label className="label">Label</label>
                <input
                  className="input"
                  value={formData.label}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      label: e.target.value,
                    })
                  }
                  placeholder="Enter email subject"
                />
              </div>
              <div className="form-group">
                <label className="label">Subject Line</label>
                <input
                  className="input"
                  value={
                    currentLanguage === "english"
                      ? formData.subjectEnglish
                      : formData.subjectItalian
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [currentLanguage === "english"
                        ? "subjectEnglish"
                        : "subjectItalian"]: e.target.value,
                    })
                  }
                  placeholder="Enter email subject"
                />
              </div>

              <div className="form-group">
                <div className="form-group-header">
                  <label className="label">Email Content (HTML)</label>
                  <button
                    className="button-outline button-small"
                    onClick={formatHtmlContent}
                    type="button"
                  >
                    Format Code
                  </button>
                </div>
                <HtmlEditorToolbar
                  onFormat={(formattedText) => {
                    const textarea = document.querySelector("textarea");
                    if (textarea) {
                      const start = textarea.selectionStart;
                      const end = textarea.selectionEnd;
                      const currentContent =
                        currentLanguage === "english"
                          ? formData.emailEnglish
                          : formData.emailItalian;
                      const newContent =
                        currentContent.substring(0, start) +
                        formattedText +
                        currentContent.substring(end);

                      setFormData({
                        ...formData,
                        [currentLanguage === "english"
                          ? "emailEnglish"
                          : "emailItalian"]: newContent,
                      });
                    }
                  }}
                />
                <textarea
                  className="textarea"
                  value={
                    currentLanguage === "english"
                      ? formData.emailEnglish
                      : formData.emailItalian
                  }
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [currentLanguage === "english"
                        ? "emailEnglish"
                        : "emailItalian"]: e.target.value,
                    })
                  }
                  placeholder="Enter email HTML content"
                />
              </div>

              <div className="form-actions">
                <button className="button-primary" onClick={handleSave}>
                  <Save className="icon" /> Save Template
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailTemplateEditor;
